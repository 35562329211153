@import "../../theme/variables";
@import "../../theme/mixins";

// Export colors from variables for React component to import and use
:export {
  colorSuccess: $colorSuccess;
}


$primaryBackground: rgba(0, 0, 0, 0.05);
$headerBackground: rgba(0, 0, 0, 0.15);
$winnerItemBackground: rgba(94, 190, 63, 0.2);
$winnerBackground: rgba(94, 190, 63, 0.4);
$excludedBackground: rgba(237, 191, 193, 0.8);

.wrap {
  display: block;
  overflow-x: auto;
  width: 100%;

  -webkit-overflow-scrolling: touch;
}

.table {
  position: relative;
  max-width: 100%;
  width: 100%;
  border-spacing: 4px;
  font-size: 14px;
  font-family: "Rubik", sans-serif;

  @include for-size(tablet-portrait-max) {
    tbody {
      td {
        min-width: 130px;
      }

      th {
        min-width: 180px;
      }
    }
  }

  table {
    table-layout: fixed;
  }

  thead,
  tbody,
  tfoot {
    td,
    th {
      padding: 14px 16px;
      border-radius: 4px;
      word-break: break-word;
    }
  }

  thead {
    td {
      background-color: $headerBackground;
    }

    p {
      margin-bottom: 5px;
    }

    .supplier {
      margin-bottom: 10px;
      color: $colorInput;
      font-weight: 500;
    }

    .date {
      font-size: 11px;
    }
  }

  tbody,
  tfoot {
    td {
      background-color: $primaryBackground;
    }
  }

  tbody {
    th {
      min-width: 220px;
    }
    td {
      min-width: 150px;
    }
  }

  .difference {
    font-size: 13px;

    th {
      font-weight: 400;
    }

    th,
    td {
      background-color: transparent;
    }
  }

  .winner-item {
    padding: 4px 8px;
    border-radius: 4px;
    background-color: $winnerItemBackground;
  }
}

.icon {
  height: 14px;
}

.item {
  display: flex;
  align-items: center;
  min-height: 20px;

  .item-number {
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    width: 21px;
    height: 21px;
    border-radius: 50%;
    background: $labelDefault;
    color: white;
    font-weight: 700;
    font-size: 13px;
  }
  
  .item-code {
    display: flex;
    font-weight: 400;
    font-size: 11px;
    margin-left: 7px;
  }

  span {
    margin-left: 7px;
  }
}



.offer-data-missing {
  margin-left: 32px;
  font-style: italic;
}

.cell {
  padding: 14px 24px !important;
}

.cell-with-select {
  display: grid;
  grid-template-columns: auto 1fr;
}

.unit-price_cell {
  padding: 30px 24px 14px 24px !important;
}

.isExcluded {
  background-color: $excludedBackground !important;
}

.isWinningSupplier {
  border: 1px red solid;
}

.align-right {
  justify-self: right;
}

.cell-select {
  padding-right: 20px;
  align-self: center;
}

.cell-contents {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 10px;
}

.question-value {
  margin-left: auto;
  max-width: 200px;
  color: $colorLightTitle;
  text-align: right;
  white-space: normal;
  font-weight: 400;
}

.cell-contents-label,
.cell-contents-value {
  overflow: hidden;
  min-height: 20px;
  max-width: 200px;
  color: $colorLightTitle;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 400;

  strong {
    color: $colorInput;
  }
}

.cell-content-wrap { 
  display: grid;
  grid-template-columns: auto;
  justify-content: flex-end;
  align-items: center;

  .item-supplier-code {
    margin-top: 7px;
    grid-column: 1 / -1;
    text-align: right;
  }

  &__cherry-pick-winner {
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
  }
}

.cell-contents-label {
  margin-left: 23px;
}

.cell-contents-label-right {
  @extend .cell-contents-label;

  margin-left: auto;
}

.item-questions-list {
  margin-top: 48px;
}

.item-question {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 40px;
  min-height: 54px;
  width: 100%;

  &__title {
    padding: 2px 4px;
    background: $tableFieldHighlight;
    color: $colorSecondary;
    font-weight: 400;
    font-size: 14px;
  }

  &__text {
    @include multiLineEllipsis(
      $lineHeight: 1.2em,
      $lineCount: 2,
      $bgColor: #f2f0ea
    );

    min-width: 150px;
    max-width: 400px;
    color: $colorLightTitle;
    text-align: right;
    white-space: pre-line;
    font-weight: 400;

    strong {
      color: $colorInput;
    }
  }
}

.cell-contents-label-bold {
  margin-left: 23px;
  font-weight: 500;
}

.link {
  color: $colorLightTitle;
  text-decoration: underline;
}

.open-external-icon {
  margin-left: 4px;
  min-height: 10px;
  height: 10px;
  fill: $colorSuccess;
}

.offer-total {
  text-align: right;
  font-weight: 500;
}
